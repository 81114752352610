import cookieCutter from "cookie-cutter";

export const handleExpiredRefreshToken = async () => {
    cookieCutter.set("accessToken", "", {
        expires: new Date(0),
        path: "/",
        domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
        secure: true
    });
    cookieCutter.set("refreshToken", "", {
        expires: new Date(0),
        path: "/",
        domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
        secure: true
    });
    window.location.href = "/login?sessionexpired=1";
}
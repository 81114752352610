import cookieCutter from "cookie-cutter";
import axiosAuth from "@/lib/axiosAuth";

export const handleExpiredAccessToken = async () => {
    try {
        const response = await axiosAuth.post("/api/auth/refresh-token", {
            refreshToken: cookieCutter.get("refreshToken"),
        });

        if (response.status === 200) {
            const { accessToken, refreshToken } = response.data;
            cookieCutter.set("accessToken", accessToken, {
                path: "/",
                domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
                secure: true,
            });
            if (refreshToken) {
                cookieCutter.set("refreshToken", refreshToken, {
                    path: "/",
                    domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
                    secure: true,
                });
            }
            return accessToken;
        } else {
            throw new Error("Token refresh failed");
        }
    } catch (error) {
        cookieCutter.set("accessToken", "", {
            expires: new Date(0),
            path: "/",
            domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
            secure: true
        });
        cookieCutter.set("refreshToken", "", {
            expires: new Date(0),
            path: "/",
            domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN,
            secure: true
        });
        window.location.href = "/login?sessionexpired=1";
    }
};
import { ApolloClient, InMemoryCache } from "@apollo/client";
import createUploadLink from "apollo-upload-client/public/createUploadLink.js";
import { setContext } from "@apollo/client/link/context";
import cookieCutter from "cookie-cutter";

const authLink = setContext((_, { headers }) => {
    const token = cookieCutter.get("accessToken");
    return {
        headers: {
            ...headers,
            ...(token ? { authorization: `Bearer ${token}` } : {}),
        },
    };
});

const uploadLink = createUploadLink({
    uri: `${process.env.NEXT_PUBLIC_BACKEND_URL}/graphql`,
    credentials: "include",
});
const client = new ApolloClient({
    link: authLink.concat(uploadLink),
    cache: new InMemoryCache(),
});

export default client;

import "tailwindcss/tailwind.css";
import "css/nprogress.css";
import "css/style.css";
import "css/privacy.css";
import "css/quill.css";
import "css/_bigcalendar/bigcalendar.scss";
import "css/_bigcalendar/SimpleModal.css";
import "video.js/dist/video-js.css";
import client from "../../apollo-client";
import { AnalyticsProvider } from "@/hooks/analytics";
import { GoogleAnalytics, event } from "nextjs-google-analytics";
import { ApolloProvider } from "@apollo/client";
import { useEffect, useRef, useState } from "react";
import Notification from "@/components/Notification";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { config } from "@fortawesome/fontawesome-svg-core";
import { GoogleOAuthProvider } from "@react-oauth/google";
import NetworkStatus from "@/components/NetworkStatus";
import Script from "next/script";
import { store, wrapper } from "@/store";
import { Provider, useDispatch, useSelector } from "react-redux";
import Cookies from "universal-cookie";
import { isPublicPage, sendMessageToServiceWorker } from "@/utilities";
import { hideBannerStatus } from "@/services/slices/otherReducersSlice";
import { resetNotification } from "@/services/slices/notificationSlice";
import { fetchContentfulData } from "@/services/slices/contentful.slice";

export function reportWebVitals({ id, name, label, value }) {
    event(name, {
        category: label === "web-vital" ? "Web Vitals" : "Custom Metrics",
        value: Math.round(name === "CLS" ? value * 1000 : value), // values must be integers
        label: id, // id unique to current page load
        nonInteraction: true, // avoids affecting bounce rate.
    });
}

const App = ({ Component, pageProps, router }) => {
    config.autoAddCss = false;
    const { query } = router;
    const { verified } = query;
    const cookies = new Cookies();
    const dispatch = useDispatch();
    const [notification, setNotification] = useState(null);
    const { content } = useSelector((store) => store.notification);
    const contentRef = useRef(null);

    // !registering service workers when app started:
    useEffect(() => {
        if ("serviceWorker" in navigator && cookies.get("accessToken")) {
            window.addEventListener("load", () => {
                navigator.serviceWorker
                    .register("/service-worker.js", { scope: "/" })
                    .then((registration) => {
                        console.log(
                            "Service Worker registered with scope:",
                            registration.scope
                        );
                    })
                    .catch((error) => {
                        console.error(
                            "Service Worker registration failed:",
                            error
                        );
                    });
            });
        }
    }, []);

    useEffect(() => {
        if (content) {
            if (contentRef.current) clearTimeout(contentRef.current);
            contentRef.current = setTimeout(() => {
                dispatch(resetNotification());
            }, 5000);
        }
        return () => {
            clearTimeout(contentRef.current);
        };
    }, [content]);

    useEffect(() => {
        if (verified === "1") {
            setNotification({
                message: "Email verified successfully!",
                type: "success",
            });
            setTimeout(() => setNotification(null), 5000);
        }
    }, [verified]);

    const { hideBanner } = useSelector((store) => store.other);

    useEffect(() => {
        dispatch(hideBannerStatus(cookies.get("isBannerHeaderClosed")));
        store.dispatch(fetchContentfulData());
    }, []);

    return (
        <ApolloProvider client={client}>
            <AnalyticsProvider>
                <GoogleAnalytics />
                <GoogleOAuthProvider
                    clientId={process.env.NEXT_PUBLIC_GOOGLE_WEBAPP_CLIENT_ID}
                >
                    <div
                        className={
                            isPublicPage(router) && !hideBanner
                                ? "pt-[68px] md:pt-[44px] relative min-h-screen"
                                : "relative min-h-screen max-h-screen overflow-y-auto"
                        }
                    >
                        <Component {...pageProps} />
                    </div>
                    <NetworkStatus />
                    {notification && (
                        <Notification
                            message={notification.message}
                            type={notification.type}
                        />
                    )}
                    {content && (
                        <Notification
                            message={content.message}
                            type={content.type}
                        />
                    )}
                    <Script
                        id="hs-script-loader"
                        src={`//js.hs-scripts.com/${process.env.NEXT_PUBLIC_HUBSPOT_PORTAL_ID}.js`}
                        strategy="afterInteractive"
                        async
                    />
                </GoogleOAuthProvider>
            </AnalyticsProvider>
        </ApolloProvider>
    );
};

export default wrapper.withRedux(App);

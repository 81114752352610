import {
    awsPublicS3Config,
    companyRoles,
    defaultCurrency,
    engineerRoles,
    internalRoutes,
    mappedTimeZones,
    placeholderColors,
    redactedPlaceholder,
    uuidValidationRegEx,
} from "@/constants";
import { NextSeo } from "next-seo";
import moment from "moment/moment";
import { PutObjectCommand, S3Client } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import axios from "axios";
import Head from "next/head";
import { setNotification } from "./services/slices/notificationSlice";
import {
    faCheck,
    faExclamation,
    faList,
    faVideoCamera,
    faX,
} from "@fortawesome/free-solid-svg-icons";
import Tooltip from "@material-tailwind/react/components/Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment } from "react";
import { Field } from "formik";
import Select, { components } from "react-select";
import Alert from "./components/Alert";
import { useRouter } from "next/router";

export const classNames = (...classes) => classes.filter(Boolean).join(" ");

const getPageMetadata = (
    title,
    description,
    url,
    image,
    imageAlt,
    canonical = null
) => {
    const openGraphType = "website";
    const twitterHandler = "@tacnique";
    const twitterCardLargeContent = "summary_large_image";

    return (
        <>
            <NextSeo
                title={title}
                description={description}
                openGraph={{
                    title,
                    description,
                    type: openGraphType,
                    images: [
                        {
                            url: image,
                            alt: imageAlt,
                        },
                    ],
                    url,
                }}
                {...(canonical && { canonical })}
            />
            <Head>
                <meta name="twitter:card" content={twitterCardLargeContent} />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={description} />
                <meta name="twitter:image" content={image} />
                <meta name="twitter:image:alt" content={imageAlt} />
                <meta name="twitter:site" content={twitterHandler} />
                <meta name="twitter:creator" content={twitterHandler} />
            </Head>
        </>
    );
};

export const getHeader = (path, headers) => {
    const defaultTitle =
        "The Future of Tech Hiring | Tacnique's Virtual Interview Platform";
    const defaultDescription =
        "Revolutionize your tech hiring process with Tacnique's virtual interview platform. Streamline your hiring solutions and find top tech talent from anywhere.";
    const defaultDescription2 =
        "Revolutionize your tech hiring process with Tacnique's virtual interview platform. Streamline your tech hiring and find top tech talent from anywhere.";
    const defaultUrl =
        process.env.NEXT_PUBLIC_SITE_URL || "https://tacnique.com";
    const defaultImage = "/home/problem-solving.png";
    const defaultImageAlt = "Outsource Your Tech Interviews Now with Tacnique";
    const canonical = "https://tacnique.com";

    if (path === "/") {
        const image = "/home/problem-solving.png";
        const imageAlt = "Get Benefits of Tech Hiring Platform";
        return getPageMetadata(
            defaultTitle,
            defaultDescription,
            defaultUrl,
            image,
            imageAlt
        );
    } else if (path === "/about") {
        const aboutTitle = "About Tacnique | Our Story and Mission";
        const aboutDescription =
            "Learn about our vision and mission at Tacnique. Our story of how we strive to revolutionize the job interview process for both employers and job seekers.";
        const image = "/about/hero.svg";
        const imageAlt =
            "Transform Tech Hiring With Impartial Interview Platform";
        return getPageMetadata(
            aboutTitle,
            aboutDescription,
            `${defaultUrl}/about`,
            image,
            imageAlt
        );
    } else if (path === "/signup") {
        const signupTitle = "Sign Up | Tacnique";
        const signupDescription =
            "Sign up for Tacnique and streamline your tech hiring or find relevant job offers.";
        const image = "/women-smiling.png";
        const imageAlt = "Signup and Access Tech Hiring Platform";
        return getPageMetadata(
            signupTitle,
            signupDescription,
            `${defaultUrl}/signup`,
            image,
            imageAlt
        );
    } else if (path === "/login") {
        const loginTitle = "Access Your Tacnique Account | Login Here";
        const loginDescription =
            "Log in to your Tacnique account to find relevant software engineers to staff your open roles or find new jobs.";
        const image = "/women-smiling.png";
        const imageAlt = "Login and Access Tech Hiring Platform";
        return getPageMetadata(
            loginTitle,
            loginDescription,
            `${defaultUrl}/login`,
            image,
            imageAlt
        );
    } else if (path === "/engineers") {
        const engineersTitle =
            "Virtual Interview Platform for Engineers | Tacnique";
        const engineersDescription =
            "Elevate job hunt with virtual interviews. Get AI-powered recommendations for job offers. One-Click Hire for seamless hiring.";
        const image = "/home/hero-main-img-new.png";
        const imageAlt = "Meet Cloud-based Hiring Platform";
        return getPageMetadata(
            engineersTitle,
            engineersDescription,
            `${defaultUrl}/engineers`,
            image,
            imageAlt
        );
    } else if (path === "/demo") {
        const demoTitle = "Tacnique's Demo | See Our Features In Action";
        const demoDescription =
            "Discover the future of tech hiring with Tacnique. Our cutting-edge platform helps you hire the right talent quickly and easily. Book a demo with us, today!";
        const imageAlt = "Outsource Your Tech Interviews Now";
        return getPageMetadata(
            demoTitle,
            demoDescription,
            `${defaultUrl}/demo`,
            defaultImage,
            imageAlt
        );
    } else if (path === "/companies/outsource-tech-interview") {
        const outsourceTechInterviewTitle =
            "Transform Your Tech Hiring Process with Tech Interview Outsourcing";
        const outsourceTechInterviewDescription =
            "Embrace the future of tech hiring with Tech Interview Outsourcing. Enhance candidate experience, simplify logistics, and make data-driven decisions. Get started now!";
        const image = "/home/hero-main-img.png";
        const imageAlt = "Outsource Tech Interviews and Transform your Hiring";
        return getPageMetadata(
            outsourceTechInterviewTitle,
            outsourceTechInterviewDescription,
            `${defaultUrl}/companies/outsource-tech-interview`,
            image,
            imageAlt
        );
    } else if (path === "/companies/verified-talent-pool") {
        const verifiedTalentPoolTitle =
            "Access a Verified Talent Pool | Tacnique";
        const verifiedTalentPoolDescription =
            "Access a large pool of screened and pre-evaluated software engineers that are relevant to your open roles. Contact us Now!";
        const image = "/home/hero-cls-main.png";
        const imageAlt = "Get benefits of a Verified Talent Pool";
        return getPageMetadata(
            verifiedTalentPoolTitle,
            verifiedTalentPoolDescription,
            `${defaultUrl}/companies/verified-talent-pool`,
            image,
            imageAlt
        );
    } else if (path === "/terms-and-conditions") {
        const termsAndConditionsTitle =
            "Tacnique | Understanding Our Terms and Conditions";
        const termsAndConditionsDescription =
            "Learn about Tacnique's terms and conditions, including user responsibilities and rights. Understand our policies and how they protect your interests.";
        const imageAlt = "Terms and Conditions to Follow";
        return getPageMetadata(
            termsAndConditionsTitle,
            termsAndConditionsDescription,
            `${defaultUrl}/terms-and-conditions`,
            defaultImage,
            imageAlt
        );
    } else if (path === "/privacy-policy") {
        const privacyPolicyTitle =
            "Tacnique | Privacy Policy and Rights Overview";
        const privacyPolicyDescription =
            "Privacy a priority at Tacnique. Discover your data protection rights and how we ensure secure information. Read our Privacy Policy now.";
        const imageAlt = "Privacy Policy and Information Collected";
        return getPageMetadata(
            privacyPolicyTitle,
            privacyPolicyDescription,
            `${defaultUrl}/privacy-policy`,
            defaultImage,
            imageAlt
        );
    } else if (path === "/cookie-policy") {
        const cookiePolicyTitle = "Tacnique | Guide to Use of Website Cookies";
        const cookiePolicyDescription =
            "Our Cookie Policy outlines how Tacnique uses cookies to improve your browsing experience. Learn more about our privacy practices and cookie usage here.";
        const imageAlt = "Cookie Policy to Read";
        return getPageMetadata(
            cookiePolicyTitle,
            cookiePolicyDescription,
            `${defaultUrl}/cookie-policy`,
            defaultImage,
            imageAlt
        );
    } else if (path === "/companies") {
        const companiesTitle =
            "Outsource Your Technical Interviews to Tacnique Today";
        const companiesDescription =
            "Build agile and accountable technology teams at blazing speed with the power of an AI-driven cloud hiring platform. Outsource your Tech Hiring today.";
        return getPageMetadata(
            companiesTitle,
            companiesDescription,
            `${defaultUrl}/companies`,
            defaultImage,
            defaultImageAlt,
            canonical
        );
    } else if (path === "/businesses") {
        const businessesTitle =
            "Outsource Your Technical Interviews to Tacnique Today";
        const businessesDescription =
            "Build agile and accountable technology teams at blazing speed with the power of an AI-driven cloud hiring platform. Outsource your Tech Hiring today.";
        return getPageMetadata(
            businessesTitle,
            businessesDescription,
            `${defaultUrl}/businesses`,
            defaultImage,
            defaultImageAlt,
            canonical
        );
    } else if (headers && headers.length) {
        const header = headers.join(" < ") + " | Tacnique";
        return getPageMetadata(
            header,
            defaultDescription2,
            `${defaultUrl}${path}`,
            defaultImage,
            defaultImageAlt
        );
    } else {
        return getPageMetadata(
            defaultTitle,
            defaultDescription2,
            `${defaultUrl}${path}`,
            defaultImage,
            defaultImageAlt
        );
    }
};

export const replaceUnderscoreWithSpace = (string) =>
    string?.replaceAll("_", " ") ?? "";

export const replaceUnderScoreWithDash = (string) =>
    string?.replaceAll("_", "-") ?? "";

export const replaceSpaceWithDash = (string) =>
    string?.replaceAll(" ", "-") ?? "";

export const replaceSpaceWithUnderScore = (string) =>
    string?.replaceAll(" ", "_") ?? "";

export const capitalizeFirstLetter = (string) =>
    string
        ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
        : "";

export const capitalizeFirstLetterOfAllWords = (string) =>
    string
        ?.toLowerCase()
        ?.split(" ")
        ?.map((word) => word?.charAt(0)?.toUpperCase() + word?.substring(1))
        ?.join(" ") ?? "";

export const capitalizeFirstLetterOfAllTheWords = (str) => {
    return str?.replace(/\b\w/g, function (char) {
        return char?.toUpperCase();
    });
};

export const getRequirementSlug = ({
    name,
    country,
    city,
    organization,
    work_place,
    uuid,
}) => {
    const slug = [];

    if (organization) {
        slug.push(organization.name);
    }

    if (name) {
        slug.push(name);
    }

    if (country) {
        slug.push(country.name);
    }

    if (city) {
        slug.push(city.name);
    }

    if (work_place) {
        slug.push(replaceUnderscoreWithSpace(work_place));
    }

    if (uuid) {
        slug.push(uuid);
    }

    return replaceSpaceWithDash(slug.join("-").toLowerCase());
};

export const extractUuidFromEnd = (string) =>
    string.split("-").slice(-5).join("-");

export const delay = (() => {
    let timer = 0;

    return (callback, ms) => {
        clearTimeout(timer);
        timer = setTimeout(callback, ms);
    };
})();

export const filterCurrency = (
    value,
    countries,
    setCurrency,
    setCurrencySuffix
) => {
    const filteredCountry = countries?.filter(
        (country) => country.id.toString() === value.toString()
    );
    setCurrency(filteredCountry[0]?.currency ?? defaultCurrency);
    setCurrencySuffix(filteredCountry[0]?.currency_suffix);
};

export const filterCities = (query, cities) => {
    return query === ""
        ? cities?.country?.cities ?? []
        : cities?.country?.cities?.filter((city) =>
              city.name.toLowerCase().includes(query.toLowerCase())
          ) ?? [];
};

export const getScoreBadgeColor = (value) => {
    let rating, rating_text;
    value = value.toLowerCase();

    if (value === "exceptional") {
        rating = "bg-green-300";
        rating_text = "text-green-800";
    } else if (value === "above Average") {
        rating = "bg-green-100";
        rating_text = "text-green-800";
    } else if (value === "average") {
        rating = "bg-yellow-100";
        rating_text = "text-yellow-800";
    } else if (value === "below average") {
        rating = "bg-red-100";
        rating_text = "text-red-800";
    } else if (value === "unsatisfactory") {
        rating = "bg-red-300";
        rating_text = "text-red-800";
    } else {
        rating = "bg-green-100";
        rating_text = "text-green-800";
    }

    return {
        rating,
        rating_text,
    };
};

export const getHashedValue = (string) =>
    Math.abs(
        string.split("").reduce((a, b) => {
            a = (a << 5) - a + b.charCodeAt(0);
            return a & a;
        }, 0)
    );

export const getStaticColor = (string) =>
    placeholderColors[getHashedValue(string) % placeholderColors.length] ??
    "bg-primary-500";

export const siteCss = (element, property) =>
    window.getComputedStyle(element, null).getPropertyValue(property);

export const getNoticePeriod = (remainingNoticePeriod) =>
    remainingNoticePeriod > 0
        ? `${remainingNoticePeriod} ${
              remainingNoticePeriod === 1 ? "day" : "days"
          }`
        : "Immediate";

export const trimExtraSpaces = (string) => string.replace(/ +/g, " ").trim();

export const getDefaultTimeZone = () => {
    let { timeZone } = new Intl.DateTimeFormat("default").resolvedOptions();

    if (Object.keys(mappedTimeZones).includes(timeZone)) {
        timeZone = mappedTimeZones[timeZone];
    }

    return timeZone;
};

export const arrayIntersection = (a, b) => a.filter((item) => b.includes(item));

export const userRole = (user) => {
    let userIsCompany = false;
    let userIsEngineer = false;

    if (user) {
        const userRoles = user.roles.map((role) => role.name);
        userIsCompany = !!arrayIntersection(companyRoles, userRoles).length;
        userIsEngineer = !!arrayIntersection(engineerRoles, userRoles).length;
    }

    return {
        userIsCompany,
        userIsEngineer,
    };
};

export const getModifiedValues = (values, initialValues) => {
    let modifiedValues = {};

    if (values) {
        Object.entries(values).forEach((entry) => {
            let key = entry[0];
            let value = entry[1];

            if (value !== initialValues[key]) {
                modifiedValues[key] = value;
            }
        });
    }

    return Object.keys(modifiedValues);
};

export const inputReference = (input) => {
    if (input) {
        setTimeout(() => {
            input.focus();
        }, 100);
    }
};

export const getFileExtension = (url) => url?.split("?")[0].split(".").pop();

export const emailChangeNotificationHandler = (email_change) => {
    switch (email_change) {
        case "true":
            return {
                message: "Email has been changed successfully!",
                type: "success",
            };

        case "false":
            return {
                message:
                    "Request email change cannot be completed as the email is already in use!",
                type: "error",
            };

        case "expired":
        default:
            return {
                message: "Link expired, please try again!",
                type: "error",
            };
    }
};

export const formatRequirementLocation = (country, city) =>
    `${country?.name ?? "Worldwide"}${city ? ", " + city?.name : ""} Office`;

export const formatRequirementBudget = (
    budget_from,
    budget_to,
    budget_currency
) =>
    `${budget_currency ?? ""} ${budget_from ?? "N/A"}${
        budget_from && budget_currency
            ? budget_currency === defaultCurrency
                ? "L"
                : "K"
            : ""
    } to ${budget_to ?? "N/A"}${
        budget_to && budget_currency
            ? budget_currency === defaultCurrency
                ? "L"
                : "K"
            : ""
    }`;

export const returnIfExists = (data, append = "") =>
    data ? `${data} ${append}` : "N/A";

export const validateUuid = (value) => uuidValidationRegEx.test(value);

export const customReactSelectStyles = {
    control: (styles, state) => {
        return {
            fontSize: "14px",
            backgroundColor: "hsl(0, 0%, 100%)",
            borderColor: state?.isFocused
                ? "rgb(13 148 136)"
                : "hsl(0, 0%, 80%)",
            borderRadius: "5px",
            borderStyle: "solid",
            borderWidth: "1px",
            cursor: "default",
            display: "flex",
            height: "100%",
            flexWrap: "wrap",
            justifyContent: "space-between",
            minHeight: "38px",
            outline: "0!important",
            position: "relative",
            transition: "all 100ms",
            boxSizing: "border-box",
            "--tw-ring-opacity": "0.5",
            "--tw-ring-color": "rgb(139 198 206 / var(--tw-ring-opacity))",
            "--tw-border-opacity": "1",
            ":hover": {
                "--tw-ring-opacity": "0.5",
                "--tw-ring-color": "rgb(139 198 206 / var(--tw-ring-opacity))",
                "--tw-border-opacity": "1",
                borderColor: "rgb(92 176 186 / var(--tw-border-opacity))",
            },
        };
    },
    placeholder: (styles) => {
        return {
            ...styles,
            color: "hsl(0, 0%, 50%)",
            fontSize: "14px",
        };
    },
    multiValueRemove: () => ({
        color: "black",
        alignItems: "center",
        borderRadius: "2px",
        display: "flex",
        paddingLeft: "4px",
        paddingRight: "4px",
        boxSizing: "border-box",
        ":hover": {
            backgroundColor: "rgb(251 113 133)",
            colour: "black",
        },
    }),
    menuList: () => ({
        maxHeight: "300px",
        overflowY: "auto",
        paddingBottom: "4px",
        paddingTop: "4px",
        position: "relative",
        boxSizing: "border-box",
    }),
    input: () => ({
        width: "100%",
        gridArea: "1/1/2/3",
        color: "inherit",
        background: "0px center",
        opacity: "1",
        font: "inherit",
        minWidth: "2px",
        border: "0px",
        margin: "0px",
        outline: "0px",
        padding: "0px 0px 0px 2px",
        "input[type=text]": {
            "--tw-ring-color": "black",
            height: "1rem",
            minWidth: "0px !important",
            width: "100%",
            boxShadow: "none",
        },
    }),
    menu: () => ({
        top: "100%",
        backgroundColor: "hsl(0, 0%, 100%)",
        borderRadius: "4px",
        boxShadow:
            "0 0 0 1px hsl(0deg 0% 0% / 10%), 0 4px 11px hsl(0deg 0% 0% / 10%)",
        marginBottom: "8px",
        marginTop: "8px",
        position: "absolute",
        width: "100%",
        zIndex: "999",
        boxSizing: "border-box",
    }),
    option: (style, state) => ({
        backgroundColor: "transparent",
        color: state.isDisabled ? "hsl(0, 0%, 80%)" : "inherit",
        cursor: "default",
        display: "block",
        fontSize: "14px",
        padding: "8px 12px",
        width: "100%",
        userSelect: "none",
        boxSizing: "border-box",
        ":hover": {
            backgroundColor: state.isDisabled
                ? "transparent"
                : "rgb(13 148 136)",
            color: state.isDisabled ? "hsl(0, 0%, 80%)" : "rgb(255 255 255)",
        },
    }),
};

export const customReactSelectErrorStyles = {
    control: (provided) => {
        return {
            ...provided,
            borderColor: "red",
            boxShadow: "none",
            ":hover": {
                borderColor: "red",
                borderWidth: "1px",
            },
        };
    },
    option: (provided) => {
        return {
            ...provided,
            backgroundColor: "transparent",
            ":hover": {
                backgroundColor: "rgb(13 148 136)",
                color: "rgb(255 255 255)",
            },
        };
    },
    menu: (provided) => {
        return {
            ...provided,
            zIndex: "999",
        };
    },
    placeholder: (styles) => {
        return {
            ...styles,
            color: "hsl(0, 0%, 50%)",
            fontSize: "14px",
        };
    },
    input: () => ({
        width: "100%",
        gridArea: "1/1/2/3",
        color: "inherit",
        background: "0px center",
        opacity: "1",
        font: "inherit",
        minWidth: "2px",
        border: "0px",
        margin: "0px",
        outline: "0px",
        padding: "0px",
        "input[type=text]": {
            "--tw-ring-color": "black",
            height: "1rem",
            minWidth: "0px !important",
            width: "100%",
            boxShadow: "none",
        },
    }),
};

export const getScreeningBgColor = (screeningStatus) => {
    if (["Passed Level 1", "Passed Level 2"].includes(screeningStatus)) {
        return "text-green-500";
    } else if (["Failed Level 1", "Failed Level 2"].includes(screeningStatus)) {
        return "text-red-400";
    } else {
        return "text-yellow-500";
    }
};

export const parseDate = (input) => moment(input, "YYYY-MM-DD hh:mm:ss");

export const calculateQuestionRecordingIndex = (
    recordingStartedAtValues,
    questionAskedAtTime
) => {
    let low = 0;
    let high = recordingStartedAtValues?.length;

    while (low < high) {
        const mid = (low + high) >>> 1;
        if (
            parseDate(recordingStartedAtValues[mid]) <
            parseDate(questionAskedAtTime)
        )
            low = mid + 1;
        else high = mid;
    }
    return Math.max(low - 1, 0);
};

export const isRedacted = (value) => value === redactedPlaceholder;

export const userHasClientRole = (roles) =>
    roles?.some((role) => ["client-admin", "client"].includes(role.name));

export const hex2rgb = (data) =>
    `rgb(${data?.match(/\w\w/g).map((x) => +`0x${x}`)},0.8)`;

export const titleColor = (data, returnDefault = false) => {
    const [r, g, b] = hex2rgb(data).match(/\d+/g).map(Number);
    return r * 0.299 + g * 0.587 + b * 0.114 > 186
        ? "#374151"
        : returnDefault
        ? data
        : "#ffffff";
};

export const convertToFormData = (props) => {
    const formData = new FormData();

    Object.keys(props).map((key) => formData.append(key, props[key]));

    return formData;
};

export const uploadFileToS3 = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    const filename =
        "tmp/" + crypto.randomUUID() + "." + file.name.split(".").pop();
    const client = new S3Client({
        region: "ap-south-1",
        credentials: awsPublicS3Config,
    });

    const params = {
        Bucket: process.env.NEXT_PUBLIC_AWS_BUCKET,
        Key: filename,
        Body: file,
        ContentType: file.type,
    };
    const command = new PutObjectCommand(params);

    const storedData = await client.send(command);

    if (storedData) {
        return `vapor-webapp/${filename}`;
    }

    return null;
};

export const uploadVideoFileToS3 = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    const filename =
        "tmp/" + crypto.randomUUID() + "." + file.name.split(".").pop();
    const client = new S3Client({
        region: "ap-south-1",
        credentials: awsPublicS3Config,
    });

    const params = {
        Bucket: process.env.NEXT_PUBLIC_AWS_BUCKET,
        Key: filename,
        Body: file,
        ContentType: file.type,
    };
    const command = new PutObjectCommand(params);
    const url = await getSignedUrl(client, command, {
        expiresIn: 3600,
    });
    return [url, file, `vapor-webapp/${filename}`];
};

export const uploadThroughAxios = (s3URL, onProgress, file) => {
    return axios.put(s3URL, file, {
        headers: {
            "Content-Type": file.type,
        },
        onUploadProgress: (progressEvent) => {
            const progress =
                progressEvent.loaded && progressEvent.total
                    ? Math.round(
                          (progressEvent.loaded * 100) / progressEvent.total
                      )
                    : 0;
            onProgress(progress);
        },
    });
};

export const passwordPatternIsValid = (password) =>
    !password || !password.includes(" ");

export const getQueryParameterWithSpecialCharacter = (path, key) => {
    if (path) {
        const [, queryString = ""] = path.split("?");

        const queryObject = queryString
            .split("&")
            .reduce((previous, current) => {
                const [key, value] = current.split("=");
                previous.set(key, value);
                return previous;
            }, new Map());

        return queryObject.get(key) ? encodeURI(queryObject.get(key)) : null;
    } else {
        return null;
    }
};

export const recaptchaAPI = async (token) => {
    const response = await fetch("/api/reCAPTCHA", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            token,
        }),
    });
    return response.json();
};

export const preferredCitiesCheck = (cities) => {
    if (!cities) {
        return "";
    }

    const cityNames = cities.map((city) => city.name).join(", ");
    const text =
        cities.length > 1 ? "Preferred Cities are" : "Preferred City is";

    return `<span class="text-gray-500">${text} ${cityNames}</span>`;
};

export const getCookieDomain = () => {
    const location =
        typeof window !== "undefined" &&
        window.location &&
        window.location.hostname;

    return location?.includes("localhost") ? "localhost" : ".tacnique.com";
};

export const isFunction = (fn) => fn && typeof fn === "function";

export const isPublicPage = (router) =>
    !internalRoutes.find((path) => router?.pathname.startsWith(path)) ||
    router?.pathname === "/";

export const allowOnlyNumericCharacters = (e) =>
    ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

export const allowOnlyNumericSpaceHyphenDot = (e) => {
    // Allow numbers, spaces, hyphens, dots, arrows, and Backspace
    if (
        !/[\d\s.-]/.test(e.key) &&
        !/^Arrow(Up|Down|Left|Right)$/.test(e.key) &&
        e.key !== "Backspace"
    ) {
        e.preventDefault();
    }
};

export const extractErrorMessage = (response, mutation = false) => {
    try {
        const cleanedErrorMessage = response?.substring(
            response.indexOf(":") + 1
        );
        const jsonResponse = JSON.parse(cleanedErrorMessage);
        return mutation
            ? jsonResponse.response.errors
            : jsonResponse.response.errors[0].message;
    } catch (error) {
        console.error("Error parsing the response:", error);
        return null;
    }
};

export const formattedDate = (date) => moment(date).format("YYYY-MM-DD");

export const formattedTime = (date, withSeconds) =>
    moment(date).format(`HH:mm${withSeconds ? ":ss" : ""}`);

export const getUserRoles = (defaultRoles, userRoles) =>
    defaultRoles.filter((defaultRole) =>
        userRoles.find((userRole) => userRole.name === defaultRole.value)
    );

export const rolesParser = (values) =>
    values.map(({ value }) => value.toUpperCase().replace("-", "_"));

export const teamMemberUpdateParser = (values, isNew) => {
    const commonData = {
        phone: values.phone,
        timezone: values.timezone,
        roles: {
            [isNew ? "connect" : "sync"]: rolesParser(values.roles),
        },
    };

    return isNew
        ? {
              data: {
                  ...commonData,
                  name: values.name,
                  email: values.email,
              },
          }
        : {
              id: values.id,
              data: {
                  ...commonData,
                  id: values.id,
              },
          };
};

export const selectedTimeZone = (tzStrings, userTimeZone) =>
    tzStrings
        .filter((tzString) => tzString["value"] === userTimeZone)
        .map((tzString) => ({ label: tzString.label, value: tzString.value }));

export const handleCopyWithNotification = (
    event,
    message,
    setNotification,
    value
) => {
    event.preventDefault();
    navigator.clipboard
        .writeText(value)
        .then(() => {
            setNotification({
                message: message,
                type: "success",
            });
            setTimeout(() => setNotification(null), 5000);
        })
        .catch(() => {
            setNotification({
                message: "Something went wrong!",
                type: "error",
            });
            setTimeout(() => setNotification(null), 5000);
        });
};

export const handleCopyWithNotificationRedux =
    (value, message, type = "success") =>
    (dispatch) => {
        navigator.clipboard
            .writeText(value)
            .then(() => {
                dispatch(
                    setNotification({
                        message: message,
                        type: type,
                    })
                );
            })
            .catch(() => {
                dispatch(
                    setNotification({
                        message: "Something went wrong!",
                        type: "error",
                    })
                );
            });
    };

export const difficultyColorManager = (
    difficulty,
    property,
    includeDot = false
) => {
    const difficultyColorMap = {
        EASY: "green-800 bg-green-100",
        0: "green-600",
        "EASY-dot": "green-800",
        EXCEPTIONAL: "green-600",
        1: "green-500",
        "ABOVE AVERAGE": "green-500",
        MEDIUM: "yellow-800 bg-yellow-100",
        2: "yellow-500",
        "MEDIUM-dot": "yellow-500",
        AVERAGE: "yellow-500",
        HARD: "red-800 bg-red-100",
        3: "red-500",
        "HARD-dot": "red-500",
        "BELOW AVERAGE": "red-500",
        "VERY HARD": "red-800 bg-red-100",
        VERY_HARD: "red-800 bg-red-100",
        "VERY HARD-dot": "red-800",
        4: "red-600",
        UNSATISFACTORY: "red-600",
    };

    const difficultyKey = includeDot ? `${difficulty}-dot` : difficulty;

    return `${property}-${difficultyColorMap[difficultyKey] || "primary-700"}`;
};

export const makePlural = (word) =>
    word?.endsWith("y") && !"aeiou".includes(word[word.length - 2])
        ? `${word.slice(0, -1)}ies`
        : `${word}s`;

export const getSkillRecommendationIcon = (recommendation, values) => {
    let icon,
        colour,
        message,
        rgb = "grey";
    switch (recommendation) {
        case "Hire":
            icon = faCheck;
            colour = "bg-green-500";
            rgb = "rgb(132 204 22)";
            message = "Recommended";
            break;

        case "Strong Hire":
            icon = faCheck;
            colour = "bg-green-500";
            rgb = "rgb(132 204 22)";
            message = "Strongly Recommended";
            break;

        case "No Hire":
            icon = faX;
            colour = "bg-red-500";
            rgb = "red";
            message = "Not Recommended";
            break;

        case "Strong No Hire":
            icon = faX;
            colour = "bg-red-500";
            rgb = "red";
            message = "Strongly Not Recommended";
            break;

        case null:
        default:
            icon = faExclamation;
            colour = "bg-yellow-500";
            rgb = "rgb(250 204 21)";
            message = "Recommendation Pending";
    }

    return (
        <Tooltip content={message}>
            {values ? (
                <div className="relative sm_max:w-4/12 flex justify-center items-center">
                    <svg className="rotate-[-90deg]" width={30} height={30}>
                        <circle
                            className="svg-circle-bg"
                            stroke="#ddd"
                            cx={15}
                            cy={15}
                            r={13.5}
                            strokeWidth={3}
                        />
                        <circle
                            className="svg-circle-bg"
                            stroke={rgb}
                            cx={15}
                            cy={15}
                            r={13.5}
                            strokeWidth={3}
                            strokeDasharray={2 * Math.PI * 13.5}
                            strokeDashoffset={
                                ((100 - values) / 100) * (2 * Math.PI * 13.5)
                            }
                        />
                    </svg>
                    <span
                        data-test-id="candidate-score"
                        className="absolute text-center"
                        style={{
                            transform: "translate(-50%, -50%)",
                            top: "50%",
                            left: "50%",
                            fontSize: "0.75rem", // Adjust font size for smaller component
                        }}
                    >
                        {values}
                    </span>
                </div>
            ) : (
                <span
                    className={classNames(
                        `h-4 w-4 rounded-full flex items-center justify-center ring-8 ring-white text-white text-xs`,
                        colour
                    )}
                >
                    <FontAwesomeIcon
                        icon={icon}
                        className="inline-block text-white"
                        aria-hidden="true"
                        size="xs"
                    />
                </span>
            )}
        </Tooltip>
    );
};

export const getTabsIcon = (value) => {
    let icon, colour, message;
    switch (value) {
        case "summary":
            icon = faList;
            colour = "bg-primary-500";
            message = "Summary";
            break;

        case "recordings":
            icon = faVideoCamera;
            colour = "bg-primary-500";
            message = "Recordings";
            break;

        case null:
        default:
            icon = faExclamation;
            colour = "bg-yellow-500";
            message = "Recommendation Pending";
    }

    return (
        <Tooltip content={message}>
            <span
                className={classNames(
                    "h-5 w-5 rounded-full flex items-center justify-center ring-8 ring-white",
                    colour
                )}
            >
                <FontAwesomeIcon
                    icon={icon}
                    className="inline-block text-white"
                    aria-hidden="true"
                    size="xs"
                />
            </span>
        </Tooltip>
    );
};

export const isEmptyObject = (objectName) =>
    objectName &&
    Object.keys(objectName).length === 0 &&
    objectName.constructor === Object;

export const checkNavItemConditions = (conditions, user) =>
    conditions.every((condition) => {
        switch (condition) {
            case "organization-has-interviewing-platform_access":
                return user.organization?.has_interviewing_platform_access;
            default:
                return true;
        }
    });

export const hasHtmlTags = (value) => {
    const htmlRegex = /<([A-Za-z][A-Za-z0-9]*)\b[^>]*>(.*?)<\/\1>/g;
    return htmlRegex.test(value);
};

export const userHasPermission = (user, permission) =>
    user?.available_permissions?.includes(permission);

export const userHasRole = (user, roleToCheck) =>
    user?.roles?.some((role) => role?.name === roleToCheck);

export const CurrencyValue = ({ currencyKey, ...props }) => (
    <components.SingleValue {...props}>
        {currencyKey ? props.data[currencyKey] : props.data.name}
    </components.SingleValue>
);

export const CompensationSuffixFrequencies = ({
    name,
    compensationFrequencyOptions,
    FrequencyError,
}) => {
    const selectedFrequency = (value) =>
        compensationFrequencyOptions?.find(
            (item) => value && item.value === value
        );

    return (
        <Field name={name}>
            {({ field, form }) => {
                return (
                    <>
                        <Select
                            id={name}
                            name={name}
                            options={compensationFrequencyOptions}
                            placeholder={""}
                            components={{
                                CurrencyValue,
                            }}
                            styles={
                                form.errors[name] && form.touched[name]
                                    ? customReactSelectErrorStyles
                                    : customReactSelectStyles
                            }
                            value={selectedFrequency(field.value)}
                            getOptionLabel={(item) => item.value}
                            getOptionValue={(item) => item.value}
                            onChange={(options) => {
                                form.setFieldValue(name, options.value);
                            }}
                            data-test-id={name}
                        />
                        {FrequencyError && <Alert message={FrequencyError} />}
                    </>
                );
            }}
        </Field>
    );
};

export const mimeIsDocType = (mime) =>
    [
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ].includes(mime);

export const generateQueryObject = (keys) => {
    const router = useRouter();
    const queryObject = {};

    keys?.forEach((key) => {
        if (router?.query[key]) {
            queryObject[key] = router.query[key];
        }
    });

    return queryObject;
};

export const renderMultiple = (element, number) =>
    Array(number)
        .fill(true)
        .map((item, index) => <Fragment key={index}>{element}</Fragment>);

export const extractDifficulty = (selected, options) => {
    const parsedOptions = options.map((item, index) => {
        return {
            label: capitalizeFirstLetter(replaceUnderscoreWithSpace(item)),
            value: replaceUnderscoreWithSpace(item),
            id: index,
        };
    });
    const exist = selected
        ? parsedOptions.find(
              (item) =>
                  replaceUnderscoreWithSpace(item.value) ===
                  replaceUnderscoreWithSpace(selected)
          )
        : null;
    return [exist, parsedOptions];
};

export const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(
        remainingSeconds
    ).padStart(2, "0")}`;
};

// Function to clear cached pages
export const deregisterAllServiceWorkers = async () => {
    try {
        // Deregister all service workers
        const registrations = await navigator.serviceWorker.getRegistrations();
        for (const registration of registrations) {
            await registration.unregister();
        }
        console.log("All service workers deregistered.");

        // Clear all caches
        const cacheNames = await caches.keys();
        for (const cacheName of cacheNames) {
            await caches.delete(cacheName);
        }
        console.log("All caches cleared.");
    } catch (error) {
        console.error("Error deregistering service workers:", error);
    }
};

export const toCamelCase = (str) => {
    return str
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
};
